const marketplaceFlags: { [key: string]: string } = {
  fr: "🇫🇷",
  it: "🇮🇹",
  es: "🇪🇸",
  de: "🇩🇪",
  en: "🇬🇧",
  us: "🇺🇸",
  jp: "🇯🇵",
  pl: "🇵🇱",
  se: "🇸🇪",
  nl: "🇳🇱",
};

export default marketplaceFlags;
