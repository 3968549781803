// src/theme/themePublic.js
import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    primary: "#319795", // Ajuster selon les besoins du site public
    secondary: "#81E6D9",
    accent: "#F6AD55",
  },
  fonts: {
    heading: `'Montserrat', sans-serif`, // Utiliser Montserrat pour les titres
    body: `'Roboto', sans-serif`, // Utiliser Roboto pour le corps de texte
  },
  // Autres configurations pour le thème public
});
