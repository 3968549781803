import React from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import { FaFileExcel } from "react-icons/fa";

interface GenerateExcelFileProps {
  projectId: string | undefined;
  bullets: string[];
}

const GenerateExcelFile: React.FC<GenerateExcelFileProps> = ({
  projectId,
  bullets,
}) => {
  const toast = useToast();

  const handleGenerateFile = async () => {
    try {
      const response = await fetch(
        "/.netlify/functions/generateAmazonFBAFile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            bullets,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to generate file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Amazon_FBA_Bulk_Listing.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast({
        title: "Fichier généré",
        description: "Le fichier Excel a été généré avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erreur de génération",
        description: "Impossible de générer le fichier Excel.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt={4} textAlign="center">
      <Button
        leftIcon={<FaFileExcel />}
        colorScheme="green"
        onClick={handleGenerateFile}
      >
        Générer fichier Amazon FBA
      </Button>
    </Box>
  );
};

export default GenerateExcelFile;
