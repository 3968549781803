import React, { useState } from "react";
import {
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useToast,
  Text,
  Box,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const marketplaces = [
  { code: "fr", label: "France", flag: "🇫🇷" },
  { code: "en", label: "United States", flag: "🇺🇸" },
  { code: "es", label: "Spain", flag: "🇪🇸" },
  { code: "it", label: "Italy", flag: "🇮🇹" },
  { code: "de", label: "Germany", flag: "🇩🇪" },
];

interface Project {
  id: string;
  marketplace: string;
  name: string;
  initializing?: boolean;
}

interface Marketplace {
  code: string;
  label: string;
  flag: string;
}

const MarketplaceSelector = ({ project }: { project: Project }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { currentUser, getToken } = useAuth();

  const handleMarketplaceChange = async (newMarketplace: Marketplace) => {
    if (currentUser) {
      const token = await getToken();

      setLoading(true);

      try {
        const response = await fetch("/.netlify/functions/copyProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            projectId: project.id,
            newMarketplace: newMarketplace.code,
          }),
        });

        if (!response.ok) {
          throw new Error(t("errorCopyProject"));
        }

        const { newProjectId } = await response.json();
        navigate(`/projects/${newProjectId}`);
      } catch (error) {
        toast({
          title: t("error"),
          description: t("errorCopyProject"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error(t("errorCopyProject"), error);
      } finally {
        setLoading(false);
      }
    }
  };

  const currentMarketplace = marketplaces.find(
    (m) => m.code === project.marketplace,
  );

  return (
    <Box textAlign="left">
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          isLoading={loading}
          size="sm"
        >
          <Badge colorScheme="white">
            {currentMarketplace?.flag} {currentMarketplace?.code.toUpperCase()}
          </Badge>
        </MenuButton>
        <MenuList>
          {marketplaces.map((marketplace) => (
            <MenuItem
              key={marketplace.code}
              onClick={() => handleMarketplaceChange(marketplace)}
            >
              <Icon mr={2} as="span">
                {marketplace.flag}
              </Icon>
              {t(marketplace.label)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Heading className="title" color="white" p="3px 0" mb={0}>
        {project.name}{" "}
        {project.initializing === true && (
          <>
            <Text fontSize="small">
              <Spinner size="sm" />
              &nbsp;{t("translationInProgress")}
            </Text>
          </>
        )}
      </Heading>
    </Box>
  );
};

export default MarketplaceSelector;
