import React, { createContext, useContext, useState, ReactNode } from "react";

interface ProjectContextProps {
  project: any;
  setProject: (project: any) => void;
  description: string;
  setDescription: (description: string) => void;
  [key: string]: any; // Pour permettre l'ajout futur d'autres éléments
}

const ProjectContext = createContext<ProjectContextProps | undefined>(
  undefined,
);

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [project, setProject] = useState<any>(null);
  const [description, setDescription] = useState<string>("");

  return (
    <ProjectContext.Provider
      value={{ project, setProject, description, setDescription }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export class Project {
  analysis: any | number;
}
