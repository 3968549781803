import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, googleProvider } from "../services/firebaseService";
import {
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from "firebase/auth";
import i18n from "../i18n"; // Importer i18n pour changer la langue

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  loginWithGoogle: () => Promise<void>;
  loginWithEmail: (email: string, password: string) => Promise<void>;
  signupWithEmail: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  getToken: () => Promise<string | null>;
  changeLanguage: (language: string) => Promise<void>;
  currentLanguage: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState<string>("fr");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        // Récupérer le profil de l'utilisateur, y compris la langue
        const token = await user.getIdToken();
        const response = await fetch("/.netlify/functions/getUserProfile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const { language } = await response.json();
        if (language) {
          setCurrentLanguage(language);
          i18n.changeLanguage(language); // Configurer i18n avec la langue de l'utilisateur
        }
      }

      if (user && location.pathname === "/login") {
        navigate("/dashboard");
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  const loginWithGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;

      await createUserInDatabase(user);

      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to log in with Google", error);
    }
  };

  const loginWithEmail = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to log in with email and password", error);
    }
  };

  const signupWithEmail = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;

      await createUserInDatabase(user);

      return userCredential;
    } catch (error) {
      console.error("Failed to sign up", error);
      throw error;
    }
  };

  const createUserInDatabase = async (user: User) => {
    try {
      const token = await user.getIdToken();
      const response = await fetch("/.netlify/functions/createUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: user.uid,
          email: user.email,
          // Autres informations utilisateur ici
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create user in the database");
      }
    } catch (error) {
      console.error("Error creating user in the database", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Failed to logout", error);
    }
  };

  const getToken = async () => {
    if (currentUser) {
      return await currentUser.getIdToken();
    }
    return null;
  };

  const changeLanguage = async (language: string) => {
    if (currentUser) {
      const token = await currentUser.getIdToken();
      await fetch("/.netlify/functions/updateUserLanguage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ language }),
      });

      setCurrentLanguage(language);
      i18n.changeLanguage(language); // Changer immédiatement la langue dans i18n
    }
  };

  const value = {
    currentUser,
    loading,
    loginWithGoogle,
    loginWithEmail,
    signupWithEmail,
    logout,
    getToken,
    changeLanguage,
    currentLanguage,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};
