import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Input,
  Button,
  Badge,
  FormControl,
  FormLabel,
  FormHelperText,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const textColor = "white";

interface CreatorProps {
  projectId: string | undefined;
  description: string;
}

interface DraggableKeywordProps {
  id: string;
  text: string;
  index: number;
  moveKeyword: (dragIndex: number, hoverIndex: number) => void;
  onRemove: (id: string) => void;
}

const DraggableKeyword: React.FC<DraggableKeywordProps> = ({
  id,
  text,
  index,
  moveKeyword,
  onRemove,
}) => {
  const [, ref] = useDrag({
    type: "KEYWORD",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "KEYWORD",
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveKeyword(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Badge
      ref={(node) => ref(drop(node))}
      colorScheme="teal"
      mr={2}
      mb={2}
      px={4}
      py={2}
      borderRadius="full"
      display="flex"
      alignItems="center"
    >
      {text}
      <IconButton
        ml={2}
        icon={<CloseIcon />}
        size="xs"
        onClick={() => onRemove(id)}
        aria-label="Remove keyword"
      />
    </Badge>
  );
};

const KeywordsCreator: React.FC<CreatorProps> = ({
  projectId,
  description,
}) => {
  const { currentUser, getToken, currentLanguage } = useAuth();
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggesting, setSuggesting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const toast = useToast();

  useEffect(() => {
    if (description.length > 2) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [description]);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await fetch("/.netlify/functions/getKeywords", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            userId: currentUser?.uid,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setKeywords(data.keywords || []);
        } else {
          throw new Error(t("errorFetchKeywords"));
        }
      } catch (error) {
        toast({
          title: t("error"),
          description: t("errorFetchKeywords"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error fetching keywords:", error);
      }
    };

    fetchKeywords();
  }, [projectId, currentUser, toast, t]);

  const handleAddKeyword = async () => {
    if (!inputValue.trim()) {
      return;
    }

    const newKeywords = inputValue.split(",").map((kw) => kw.trim());
    const updatedKeywords = [...keywords, ...newKeywords];
    setKeywords(updatedKeywords);
    setInputValue("");

    await saveKeywords(updatedKeywords);
  };

  const handleRemoveKeyword = async (id: string) => {
    const updatedKeywords = keywords.filter((keyword) => keyword !== id);
    setKeywords(updatedKeywords);

    await saveKeywords(updatedKeywords);
  };

  const moveKeyword = (dragIndex: number, hoverIndex: number) => {
    const updatedKeywords = [...keywords];
    const [draggedKeyword] = updatedKeywords.splice(dragIndex, 1);
    updatedKeywords.splice(hoverIndex, 0, draggedKeyword);
    setKeywords(updatedKeywords);
    saveKeywords(updatedKeywords);
  };

  const handleSuggestKeywords = async () => {
    setSuggesting(true);
    try {
      const token = await getToken();
      const response = await fetch("/.netlify/functions/suggestKeywords", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          projectId,
          description,
          userId: currentUser?.uid,
          currentLanguage,
        }),
      });

      if (!response.ok) {
        throw new Error(t("errorSuggestKeywords"));
      }

      toast({
        title: t("suggestingKeywords"),
        description: t("keywordsProcessing"),
        status: "info",
        duration: 3000,
        isClosable: true,
      });

      pollForKeywords();
    } catch (error) {
      toast({
        title: t("error"),
        description: t("errorSuggestKeywords"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error suggesting keywords:", error);
    }
  };

  const pollForKeywords = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch("/.netlify/functions/getKeywords", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            userId: currentUser?.uid,
          }),
        });

        const result = await response.json();

        if (response.status === 200 && result.keywords) {
          setKeywords(result.keywords);
          setSuggesting(false);
          clearInterval(intervalId);
          toast({
            title: t("keywordsUpdated"),
            description: t("suggestedKeywordsAdded"),
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        clearInterval(intervalId);
        setSuggesting(false);
        toast({
          title: t("error"),
          description: t("errorFetchUpdatedKeywords"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error fetching updated keywords:", error);
      }
    }, 3500);
  };

  const saveKeywords = async (updatedKeywords: string[]) => {
    try {
      const response = await fetch("/.netlify/functions/saveKeywords", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
          userId: currentUser?.uid,
          keywords: updatedKeywords,
        }),
      });

      if (!response.ok) {
        throw new Error(t("errorSaveKeywords"));
      }
    } catch (error) {
      toast({
        title: t("error"),
        description: t("errorSaveKeywords"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error saving keywords:", error);
    }
  };

  return (
    <Box position="relative" mt={4}>
      <DndProvider backend={HTML5Backend}>
        {/* Overlay */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          style={{ borderRadius: "" + "30px" }}
          bg="rgba(0, 0, 0, 0.5)"
          display={isDisabled ? "block" : "none"}
          pointerEvents="none"
          transition="opacity 0.5s ease"
          opacity={isDisabled ? 1 : 0}
          zIndex="10"
        />
        {/* Contenu du composant */}
        <Card
          p="1rem"
          style={{ marginTop: "30px" }}
          pointerEvents={isDisabled ? "none" : "auto"}
          opacity={isDisabled ? 0.5 : 1}
          transition="opacity 0.5s ease"
        >
          <CardHeader p="5px" mb="12px" style={{ width: "100%" }}>
            <Flex justify="space-between" align="center">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                {t("keywords")}
              </Text>
            </Flex>
          </CardHeader>
          <FormControl id="keywords">
            <FormLabel>
              {t("addKeywords")}
              <br />
              <small>{t("reorderKeywords")}</small>
            </FormLabel>
            <Flex>
              <Input
                type="text"
                width="300px"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleAddKeyword()}
                isDisabled={isDisabled}
              />
              <Button onClick={handleAddKeyword} ml={2} isDisabled={isDisabled}>
                {t("add")}
              </Button>
              <Button
                onClick={handleSuggestKeywords}
                ml={2}
                isLoading={suggesting}
                variant="outline"
                isDisabled={isDisabled}
              >
                {t("suggest")}
              </Button>
            </Flex>
            <FormHelperText>{t("pressEnterToAddKeyword")}</FormHelperText>
          </FormControl>

          <CardBody p="0px 5px">
            <Flex wrap="wrap">
              {keywords.map((keyword, index) => (
                <DraggableKeyword
                  key={keyword}
                  id={keyword}
                  index={index}
                  text={keyword}
                  moveKeyword={moveKeyword}
                  onRemove={handleRemoveKeyword}
                />
              ))}
            </Flex>
          </CardBody>
        </Card>
      </DndProvider>
    </Box>
  );
};

export default KeywordsCreator;
