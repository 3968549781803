import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Textarea,
  Button,
  Flex,
  Badge,
  FormControl,
  FormLabel,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  SimpleGrid,
  useDisclosure,
  GridItem,
  Grid,
  FormHelperText,
  Spinner,
} from "@chakra-ui/react";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import { useAuth } from "../context/AuthContext";
import { useProject } from "../context/ProjectContext";
import CardHeader from "./Card/CardHeader";
import { useTranslation } from "react-i18next";

interface DescriptionCreatorProps {
  projectId: string | undefined;
}

const DescriptionCreator: React.FC<DescriptionCreatorProps> = ({
  projectId,
}) => {
  const { currentUser, currentLanguage, getToken } = useAuth();
  const { description, setDescription } = useProject();
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const [answer, setAnswer] = useState<string>("");
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isMac, setIsMac] = useState<boolean>(false);
  const [showHelper, setShowHelper] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [oldDescription, setOldDescription] = useState<string>(description);
  const [oldQuestions, setOldQuestions] = useState<any[]>(questions);
  const toast = useToast();

  useEffect(() => {
    const isMacOS = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    setIsMac(isMacOS);
  }, []);

  useEffect(() => {
    const fetchCustomData = async () => {
      try {
        const token = await getToken();
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const result = await response.json();

        if (response.status === 200) {
          setDescription(result.project.customDescription || "");
          setQuestions(result.project.questions || []);
          setOldDescription(result.project.customDescription || "");
          setOldQuestions(result.project.questions || []);
          setIsModified(false);
        }
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    fetchCustomData();
  }, [currentUser, projectId, setDescription]);

  const handleSaveDescription = async () => {
    setLoading(true);
    setShowHelper(false);
    const token = await getToken();

    try {
      const response = await fetch("/.netlify/functions/saveDescription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          projectId,
          userId: currentUser?.uid,
          description,
          currentLanguage,
        }),
      });

      if (response.status === 200) {
        toast({
          title: t("descriptionSaved"),
          description: t("questionsWillBeGeneratedSoon"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        pollForGeneratedQuestions();
        setIsModified(false);
      } else {
        throw new Error(t("saveDescriptionError"));
      }
    } catch (error) {
      console.error("Error saving description:", error);
      toast({
        title: t("error"),
        description: t("saveDescriptionError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const pollForGeneratedQuestions = () => {
    const intervalId = setInterval(async () => {
      try {
        const token = await getToken();
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const result = await response.json();

        if (response.status === 200 && result.project?.questions) {
          if (
            result.project.customDescription !== oldDescription ||
            JSON.stringify(result.project.questions) !==
              JSON.stringify(oldQuestions)
          ) {
            setQuestions(result.project.questions);
            setDescription(result.project.customDescription || "");
            clearInterval(intervalId);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Failed to fetch generated questions:", error);
        clearInterval(intervalId);
        setLoading(false);
      }
    }, 3500);

    // Timeout to stop polling after a certain time
    setTimeout(() => {
      clearInterval(intervalId);
      setLoading(false);
    }, 15000); // 1 minute timeout
  };

  const handleQuestionClick = (question: string) => {
    setSelectedQuestion(question);
    onOpen();
  };

  const handleSaveAnswer = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await fetch(
        "/.netlify/functions/updateDescriptionWithAnswer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            projectId,
            userId: currentUser?.uid,
            description,
            question: selectedQuestion,
            answer,
          }),
        },
      );

      if (response.status === 200) {
        pollForGeneratedQuestions();
        toast({
          title: t("answerSaved"),
          description: t("answerSavedMessage"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsModified(false);
      } else {
        throw new Error(t("updateDescriptionError"));
      }
    } catch (error) {
      toast({
        title: t("error"),
        description: t("updateDescriptionError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error updating description:", error);
      setLoading(false);
    }

    onClose();
    setAnswer("");
  };

  const handleTextareaFocus = () => {
    setShowHelper(false);
    setTimeout(() => {
      if (document.activeElement?.tagName === "TEXTAREA") {
        setShowHelper(true);
      }
    }, 2500);
  };

  const handleTextareaBlur = () => {
    setShowHelper(false);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(e.target.value);
    setIsModified(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      handleSaveDescription();
    }
  };

  return (
    <Card p="1rem" style={{ marginTop: "30px" }}>
      <CardHeader p="5px" mb="12px" style={{ width: "100%" }}>
        <Flex justify="space-between" align="center">
          <Text fontSize="lg" color="white" fontWeight="bold">
            {t("description")}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody p="5px">
        <SimpleGrid width="100%" columns={{ base: 1, md: 2 }} spacing="20px">
          <GridItem>
            <FormControl id="description">
              <FormLabel>{t("describeYourProduct")}</FormLabel>
              <Box position="relative">
                <Textarea
                  value={description}
                  onFocus={handleTextareaFocus}
                  onBlur={handleTextareaBlur}
                  onChange={handleDescriptionChange}
                  onKeyDown={handleKeyDown}
                  rows={10}
                  placeholder={t("describeYourProductPlaceholder")}
                  mb={3}
                  isDisabled={loading}
                />
                {loading && (
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="rgba(255, 255, 255, 0.6)"
                    zIndex="1"
                    borderRadius="md"
                  >
                    <Spinner size="xl" />
                  </Box>
                )}
              </Box>
              <Text
                className={`helper ${showHelper ? "show" : ""}`}
                fontSize="sm"
                color="gray.500"
              >
                {t("tip")}: {isMac ? <kbd>Cmd</kbd> : <kbd>Ctrl</kbd>} +{" "}
                <kbd>{t("enter")}</kbd> {t("toSaveQuickly")}
              </Text>
              <Button
                colorScheme="teal"
                onClick={handleSaveDescription}
                isLoading={loading}
                isDisabled={loading || !description}
                mt={3}
              >
                {t("saveDescription")}
              </Button>
              {isModified && (
                <Text color="red.500" mt={2}>
                  {t("dontForgetToSave")}
                </Text>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            {questions.length > 0 && (
              <Box mt={5}>
                <FormControl>
                  <Text className="subtitle" mb={2}>
                    {t("refineFurther")}
                    <FormHelperText>
                      {t("clickQuestionToExpand")}
                    </FormHelperText>
                  </Text>
                </FormControl>
                <Box position="relative">
                  <Flex wrap="wrap" opacity={loading ? 0.5 : 1}>
                    {questions.map((question, index) => (
                      <Badge
                        className="question"
                        key={index}
                        colorScheme="teal"
                        m={1}
                        p={2}
                        cursor="pointer"
                        onClick={() => handleQuestionClick(question)}
                      >
                        {question}
                      </Badge>
                    ))}
                  </Flex>
                  {loading && (
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bg="rgba(255, 255, 255, 0.6)"
                      zIndex="1"
                      borderRadius="md"
                    >
                      <Spinner size="xl" />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </GridItem>
        </SimpleGrid>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("specify")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={3}>{selectedQuestion}</Text>
              <Input
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder={t("response")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSaveAnswer();
                  }
                }}
                isDisabled={loading}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="teal"
                onClick={handleSaveAnswer}
                isLoading={loading}
              >
                {t("saveResponse")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default DescriptionCreator;
