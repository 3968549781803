import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme, ThemeConfig } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { NavbarProvider } from "./context/NavbarContext";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import themeAdmin from "./theme/themeAdmin";
import { ProjectProvider } from "./context/ProjectContext";
import { ProjectListProvider } from "./context/ProjectListContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={themeAdmin}>
      <Router>
        <AuthProvider>
          <ProjectListProvider>
            <ProjectProvider>
              <NavbarProvider>
                <App />
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </NavbarProvider>
            </ProjectProvider>
          </ProjectListProvider>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  </React.StrictMode>,
);

reportWebVitals();
