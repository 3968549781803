import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importation des fichiers de traduction
import translationEN from "./lang/en.json";
import translationFR from "./lang/fr.json";
import translationES from "./lang/es.json";
import translationIT from "./lang/it.json";
import translationDE from "./lang/de.json";
import translationJP from "./lang/jp.json";

const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
  es: { translation: translationES },
  it: { translation: translationIT },
  de: { translation: translationDE },
  jp: { translation: translationJP },
};

i18n
  .use(initReactI18next) // Passe i18n au module react-i18next
  .init({
    resources,
    debug: true, // Mettre à false en production
    interpolation: {
      escapeValue: false, // React se charge de l'échappement
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "sessionStorage"],

      caches: ["cookie"],
      checkWhitelist: true,
    },
  });

export default i18n;
