import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Badge,
} from "@chakra-ui/react";
import { ProfileIcon } from "../Icons/Icons";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaGlobe,
  FaUserAlt,
} from "react-icons/fa"; // Import FaGlobe pour l'icône de langue
import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavbar } from "../../context/NavbarContext";
import { useTranslation } from "react-i18next";
// import svg image from assets
import svgCreditIcon from "../../assets/svg/credits.svg";
import "../../i18n";
import bmLogo from "../../assets/svg/bm_logo.svg";

export default function HeaderLinks(props) {
  const { currentUser, logout, changeLanguage } = useAuth();
  const { credits } = useNavbar();
  const { t, i18n } = useTranslation();

  const updateUserLanguageInBackend = async (lng) => {
    try {
      const response = await fetch("/.netlify/functions/updateUserLanguage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser?.uid,
          language: lng,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update language in backend");
      }
    } catch (error) {
      console.error("Error updating language:", error);
    }
  };

  const handleChangeLanguage = (lng) => {
    changeLanguage(lng);
    updateUserLanguageInBackend(lng); // Met à jour la langue côté backend
  };

  const languages = [
    { code: "fr", name: "Français", flag: "🇫🇷" },
    { code: "en", name: "English", flag: "🇬🇧" },
    { code: "es", name: "Español", flag: "🇪🇸" },
    { code: "it", name: "Italiano", flag: "🇮🇹" },
    { code: "de", name: "Deutsch", flag: "🇩🇪" },
    { code: "jp", name: "日本人", flag: "🇯🇵" },
  ];

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      m={{sm: "25px", md: "0px"}}
      alignItems="center"
      justifyContent="center" // Centrage horizontal des éléments
      flexDirection="row"
    >
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FaGlobe />}
          aria-label="Select Language"
        />
        <MenuList
          border="transparent"
          backdropFilter="blur(63px)"
          bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)"
          borderRadius="20px"
        >
          {languages.map(({ code, name, flag }) => (
            <MenuItem
              bg="transparent"
              borderRadius="8px"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              mb="10px"
              key={code}
              onClick={() => handleChangeLanguage(code)}
            >
              <Badge mr={2}>{flag}</Badge>
              <Text>{name}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          m={5}
          as={IconButton}
          icon={<FaUserAlt />}
          aria-label="User profile"
        />
        <MenuList
          border="transparent"
          backdropFilter="blur(63px)"
          bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)"
          borderRadius="20px"
        >
          <Flex
            flexDirection="column"
            px={4}
            py={2}
            bg="transparent"
            borderRadius="8px"
          >
            <img
              src={svgCreditIcon}
              alt="{credits}"
              width="35px"
              style={{ position: "absolute" }}
            />{" "}
            <Text
              style={{ paddingTop: "5px", paddingLeft: "45px" }}
              fontWeight="bold"
              bg="transparent"
              color="teal.200"
            >
              {credits}
            </Text>
          </Flex>
          <Flex flexDirection="column" mt="10px">
            <NavLink to="/account">
              <MenuItem
                bg="transparent"
                borderRadius="8px"
                _hover={{
                  bg: "teal",
                }}
                _active={{
                  bg: "transparent",
                }}
                _focus={{
                  bg: "transparent",
                }}
                mb="10px"
              >
                <Flex align="center">
                  <FaUser size="20px" style={{ marginRight: "12px" }} />
                  <Text>{t("my_account")}</Text>
                </Flex>
              </MenuItem>
            </NavLink>
            <NavLink to="/subscribe">
              <MenuItem
                bg="transparent"
                borderRadius="8px"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                mb="10px"
              >
                <Flex align="center">
                  <FaCog size="20px" style={{ marginRight: "12px" }} />
                  <Text>{t("manage_subscription")}</Text>
                </Flex>
              </MenuItem>
            </NavLink>
            <MenuItem
              borderRadius="8px"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              onClick={logout}
            >
              <Flex align="center">
                <FaSignOutAlt size="20px" style={{ marginRight: "12px" }} />
                <Text>{t("logout")}</Text>
              </Flex>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
