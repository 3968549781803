// src/pages/CreateProject.tsx
import React, { useState } from "react";
import {
  Box,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Switch,
  Checkbox,
  HStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import CreateProjectCard from "./CreateProjectCard";

const CreateProject: React.FC = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [asin, setAsin] = useState<string>("");
  const [category, setCategory] = useState<string>(""); // Select pour les catégories
  const [isActive, setIsActive] = useState<boolean>(true); // Switch pour activer/désactiver
  const [flags, setFlags] = useState<{ [key: string]: boolean }>({
    flag1: false,
    flag2: false,
  }); // Drapeaux sous forme de checkboxes
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name || !category) {
      // Vérification des champs requis
      setError("Le nom du projet et la catégorie sont requis.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch("/.netlify/functions/createProject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser?.uid,
          name,
          description,
          asin,
          category,
          isActive,
          flags,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create project");
      }

      navigate("/dashboard"); // Redirection après création réussie
    } catch (err) {
      console.error("Error creating project:", err);
      setError("Une erreur est survenue lors de la création du projet.");
    } finally {
      setLoading(false);
    }
  };

  const handleFlagChange = (flagName: string) => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      [flagName]: !prevFlags[flagName],
    }));
  };

  return <CreateProjectCard />;
};

export default CreateProject;
