// src/pages/Account.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    Avatar,
    Divider,
    SimpleGrid,
    InputGroup,
    InputLeftElement,
    Flex,
} from '@chakra-ui/react';
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import { updateProfile, updatePassword } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebaseService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Account = () => {
    const { currentUser, logout } = useAuth();
    const [displayName, setDisplayName] = useState(currentUser?.displayName || '');
    const [photoURL] = useState(currentUser?.photoURL || '');
    const [email] = useState(currentUser?.email || '');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [credits, setCredits] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [billingAddress, setBillingAddress] = useState('');

    useEffect(() => {
        if (currentUser) {
            const docRef = doc(db, 'users', currentUser.uid);
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setFirstName(data.firstName || '');
                    setLastName(data.lastName || '');
                    setCompany(data.company || '');
                    setBillingAddress(data.billingAddress || '');
                    setCredits(data.credits || 0);
                }
            });
        }
    }, [currentUser]);

    const handleSaveChanges = async () => {
        try {
            if (currentUser) {
                await updateProfile(currentUser, {
                    displayName,
                    photoURL,
                });

                const docRef = doc(db, 'users', currentUser.uid);
                await updateDoc(docRef, {
                    firstName,
                    lastName,
                    company,
                    billingAddress,
                });

                toast.success('Profile updated successfully');
            }
        } catch (error) {
            toast.error('Failed to update profile');
            console.error('Failed to update profile', error);
        }
    };

    const validatePassword = (password: string) => {
        if (password.length < 6) {
            return 'Le mot de passe doit contenir au moins 6 caractères';
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            return 'Le mot de passe doit contenir au moins un caractère spécial';
        }
        if (!/\d/.test(password)) {
            return 'Le mot de passe doit contenir au moins un numéro';
        }
        return null;
    };

    const handleChangePassword = async () => {
        const validationError = validatePassword(password);
        if (validationError) {
            toast.error(validationError);
            return;
        }

        if (password !== repeatPassword) {
            toast.error('Les mots de passe ne correspondent pas');
            return;
        }

        if (currentUser && password) {
            try {
                await updatePassword(currentUser, password);
                toast.success('Password updated successfully');
            } catch (error) {
                toast.error('Failed to update password');
                console.error('Failed to update password', error);
            }
        }
    };

    return (
        <Box p={8} margin="0 auto">
            <Stack spacing={6}>

                <Card>
                    <CardHeader>
                        <Text fontSize="xl" fontWeight="bold">Profil</Text>
                    </CardHeader>
                    <CardBody>
                        <SimpleGrid width='100%' columns={{ base: 1, md: 2 }} spacing='20px'>
                            <FormControl id="displayName">
                                <FormLabel>Nom d'utilisateur</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="@" />
                                    <Input
                                        type="text"
                                        value={displayName}
                                        onChange={(e) => setDisplayName(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="email">
                                <FormLabel>Email</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="@" />
                                    <Input
                                        type="email"
                                        value={email}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="firstName">
                                <FormLabel>Prénom</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="👤" />
                                    <Input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="lastName">
                                <FormLabel>Nom</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="👤" />
                                    <Input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="company">
                                <FormLabel>Entreprise</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="🏢" />
                                    <Input
                                        type="text"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="billingAddress">
                                <FormLabel>Adresse de facturation</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="🏠" />
                                    <Input
                                        type="text"
                                        value={billingAddress}
                                        onChange={(e) => setBillingAddress(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Button colorScheme="teal" onClick={handleSaveChanges}>
                                    Sauvegarder les modifications
                                </Button>
                            </Flex>
                        </SimpleGrid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <Text fontSize="xl" fontWeight="bold">Mot de passe</Text>
                    </CardHeader>
                    <CardBody>
                        <SimpleGrid width='100%' columns={{ base: 1, md: 2 }} spacing='20px'>
                            <FormControl id="password">
                                <FormLabel>Nouveau mot de passe</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="🔒" />
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="repeatPassword">
                                <FormLabel>Répéter mot de passe</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children="🔒" />
                                    <Input
                                        type="password"
                                        value={repeatPassword}
                                        onChange={(e) => setRepeatPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>
                            <Button colorScheme="teal" onClick={handleChangePassword} width="full" mt={4}>
                                Changer le mot de passe
                            </Button>
                        </SimpleGrid>
                    </CardBody>
                </Card>
                <Button colorScheme="red" variant="outline" onClick={logout} width="full">
                    Déconnexion
                </Button>
            </Stack>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Box>
    );
};

export default Account;