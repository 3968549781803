import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Link,
  Stack,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Button,
  DrawerCloseButton,
  DrawerBody,
  Text,
  IconButton,
} from "@chakra-ui/react";
import IconBox from "../Icons/IconBox";
import { NavLink } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { FaFile } from "react-icons/fa";
import { SimmmpleLogoWhite } from "../Icons/Icons";
import { Separator } from "../Separator/Separator";
import { SidebarHelp } from "./SidebarHelp";
import { useProjectList } from "../../context/ProjectListContext"; // Import du contexte
import bmLogo from "../../assets/svg/bm_logo.svg";
import { useTranslation } from "react-i18next"; // Import the SVG image

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { t } = useTranslation();

  const { projects, refreshProjects } = useProjectList();

  useEffect(() => {
    refreshProjects();
  }, [refreshProjects]);

  const brand = (
    <Box pt={"25px"} mb="12px">
      <Link
        href={`${process.env.PUBLIC_URL}/dashboard`}
        display="flex"
        lineHeight="100%"
        mb="30px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <img
          src={bmLogo}
          alt="BM Logo"
          width="200px"
          style={{ marginRight: "10px", marginTop: "2px" }}
        />
        <Box
          bg="linear-gradient(97.89deg, #FFFFFF 70.67%, rgba(117, 122, 140, 0) 108.55%)"
          bgClip="text"
        ></Box>
      </Link>
      <Separator />
    </Box>
  );

  const projectLinks = projects.map((project) => (
    <NavLink key={project.id} to={`/projects/${project.id}`}>
      <Button
        boxSize="initial"
        justifyContent="flex-start"
        alignItems="center"
        bg="transparent"
        mb={{ xl: "12px" }}
        mx={{ xl: "auto" }}
        py="12px"
        ps={{ sm: "10px", xl: "16px" }}
        borderRadius="15px"
        _hover="none"
        w="100%"
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "transparent",
        }}
        _focus={{ boxShadow: "none" }}
        onClick={onClose} // Fermer le drawer lorsque le lien est cliqué
      >
        <Flex>
          <IconBox bg="#1A1F37" color="white" h="30px" w="30px" me="12px">
            {project.emoji ? (
              <Text className="emojiproject">{project.emoji}</Text>
            ) : (
              <FaFile />
            )}
          </IconBox>
          <Text color="white" my="auto" fontSize="sm">
            {project.name}
          </Text>
        </Flex>
      </Button>
    </NavLink>
  ));

  return (
    <Box ref={btnRef}>
      {/* Desktop Sidebar */}
      <Box display={{ base: "none", xl: "block" }} position="fixed">
        <Box
          bg="linear-gradient(111.84deg, rgba(6, 11, 38, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%)"
          backdropFilter="blur(10px)"
          w="260px"
          maxW="260px"
          ms={{ sm: "16px" }}
          my={{ sm: "16px" }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m="16px 0px 16px 16px"
          borderRadius="16px"
        >
          <Box>{brand}</Box>
          <Stack direction="column" mb="40px">
            <Box>
              <NavLink to="/dashboard">
                <Button
                  boxSize="initial"
                  justifyContent="flex-start"
                  alignItems="center"
                  bg="transparent"
                  mb={{ xl: "12px" }}
                  mx={{ xl: "auto" }}
                  py="12px"
                  ps={{ sm: "10px", xl: "16px" }}
                  borderRadius="15px"
                  _hover="none"
                  w="100%"
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{ boxShadow: "none" }}
                >
                  <Flex>
                    <IconBox
                      bg="#1A1F37"
                      color="white"
                      h="30px"
                      w="30px"
                      me="12px"
                    >
                      <HamburgerIcon />
                    </IconBox>
                    <Text color="white" my="auto" fontSize="sm">
                      {t("dashboard")}
                    </Text>
                  </Flex>
                </Button>
              </NavLink>
              {projectLinks}
            </Box>
          </Stack>
        </Box>
      </Box>

      {/* Mobile Sidebar - Drawer */}
      <Box display={{ base: "block", xl: "none" }} className="burgerMenu">
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          ref={btnRef}
          colorScheme="teal"
        />

        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <Box>{brand}</Box>
              <Stack direction="column" mb="40px">
                <Box>
                  <NavLink to="/dashboard">
                    <Button
                      boxSize="initial"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="transparent"
                      mb={{ xl: "12px" }}
                      mx={{ xl: "auto" }}
                      py="12px"
                      ps={{ sm: "10px", xl: "16px" }}
                      borderRadius="15px"
                      _hover="none"
                      w="100%"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{ boxShadow: "none" }}
                      onClick={onClose}
                    >
                      <Flex>
                        <IconBox
                          bg="#1A1F37"
                          color="white"
                          h="30px"
                          w="30px"
                          me="12px"
                        >
                          <HamburgerIcon />
                        </IconBox>
                        <Text color="white" my="auto" fontSize="sm">
                          Dashboard
                        </Text>
                      </Flex>
                    </Button>
                  </NavLink>
                  {projects.map((project) => (
                    <NavLink key={project.id} to={`/projects/${project.id}`}>
                      <Button
                        boxSize="initial"
                        justifyContent="flex-start"
                        alignItems="center"
                        bg="transparent"
                        mb={{ xl: "12px" }}
                        mx={{ xl: "auto" }}
                        py="12px"
                        ps={{ sm: "10px", xl: "16px" }}
                        borderRadius="15px"
                        _hover="none"
                        w="100%"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{ boxShadow: "none" }}
                        onClick={onClose} // Close drawer on click
                      >
                        <Flex>
                          <IconBox
                            bg="#1A1F37"
                            color="white"
                            h="30px"
                            w="30px"
                            me="12px"
                          >
                            {project.emoji ? (
                              <Text className="emojiproject">
                                {project.emoji}
                              </Text>
                            ) : (
                              <FaFile />
                            )}
                          </IconBox>
                          <Text color="white" my="auto" fontSize="sm">
                            {project.name}
                          </Text>
                        </Flex>
                      </Button>
                    </NavLink>
                  ))}
                </Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;
