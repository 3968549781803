import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Flex,
  Text,
  Button,
  Tooltip,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import {
  FiCheckCircle,
  FiSearch,
  FiEdit,
  FiTrendingUp,
  FiHelpCircle,
} from "react-icons/fi";
import { useDialogState } from "ariakit/dialog";
import { useNavbar } from "../context/NavbarContext";
import Card from "../components/Card/Card";
import { Project } from "../context/ProjectContext";
import { useTranslation } from "react-i18next"; // Import de useTranslation pour la localisation

interface AnalyseProps {
  project: Project;
  projectId: string | undefined;
  currentUser: any;
  setProject: React.Dispatch<React.SetStateAction<Project | null>>;
}

const Analyse: React.FC<AnalyseProps> = ({
  project,
  projectId,
  currentUser,
  setProject,
}) => {
  const [analyzing, setAnalyzing] = useState<boolean>(false);
  const { updateCredits } = useNavbar();
  const toast = useToast();
  const dialog = useDialogState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(); // Initialisation du hook useTranslation

  const handleAnalyzeProject = async () => {
    setAnalyzing(true);
    try {
      const response = await fetch("/.netlify/functions/analyzeProject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
          userId: currentUser?.uid,
        }),
      });

      if (response.status !== 200) {
        toast({
          title: t("analyzeFailed"),
          description: t("analyzeFailedDescription"),
          status: "error",
          duration: 1000,
          isClosable: true,
        });
        setAnalyzing(false);
      } else {
        toast({
          title: t("analyzeStarted"),
          description: t("analyzeStartedDescription"),
          status: "success",
          duration: 1000,
          isClosable: true,
        });
        pollForAnalysis();
      }
    } catch (error) {
      console.log({ error });
      setAnalyzing(false);
    }
  };

  const pollForAnalysis = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const result = await response.json();

        if (
          response.status === 200 &&
          result.project?.analysis?.images_suggestions
        ) {
          setProject(result.project);
          setAnalyzing(false);
          clearInterval(intervalId);
          toast({
            title: t("analyzeCompleted"),
            description: t("analyzeCompletedDescription"),
            status: "success",
            duration: 1000,
            isClosable: true,
          });
          updateCredits();
        }
      } catch (error) {
        clearInterval(intervalId);
        setAnalyzing(false);
      }
    }, 3500);
  };

  const confirmAnalyzeProject = () => {
    dialog.hide();
    handleAnalyzeProject();
  };

  const getColorFromScore = (score: number) => {
    if (score >= 75) return "green.400";
    if (score >= 50) return "yellow.400";
    if (score >= 25) return "orange.400";
    return "red.400";
  };

  const renderAnalysisCard = (
    name: string,
    score: number | null | undefined, // Accepter null ou undefined
    icon: any,
    suggestion: string,
  ) => {
    const displayScore =
      score !== undefined && score !== null && score > 0 ? `${score}%` : "-- %";
    const scoreColor =
      score !== undefined && score !== null && score > 0
        ? getColorFromScore(score)
        : "gray.400";

    return (
      <GridItem colSpan={2} key={name}>
        <Card p="1.5rem" backgroundColor="#1A202C" borderRadius="lg">
          <Flex align="center" justify="space-between">
            <Box>
              <Text fontSize="md" color="white">
                {name}
              </Text>
              <Flex align="center">
                <Icon
                  as={FiHelpCircle}
                  boxSize={5}
                  color="gray.500"
                  mr={2}
                  cursor="pointer"
                />

                <Tooltip
                  label={suggestion}
                  aria-label={`${name} Tooltip`}
                  placement="bottom"
                >
                  <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    color={scoreColor}
                    cursor="pointer"
                  >
                    {displayScore}
                  </Text>
                </Tooltip>
              </Flex>
            </Box>
            <Icon as={icon} boxSize={8} color="white" />
          </Flex>
        </Card>
      </GridItem>
    );
  };

  return (
    <Box>
      <Grid templateColumns="repeat(10, 1fr)" gap={6} mt={5}>
        <GridItem colSpan={2} h="100%">
          <Card p="1.5rem" backgroundColor="#1A202C" borderRadius="lg" h="100%">
            <Flex align="center" justify="center" h="100%">
              <Button
                colorScheme="teal"
                onClick={onOpen}
                isLoading={analyzing}
                isDisabled={analyzing}
                width="100%"
              >
                {analyzing ? t("loading") : t("analyze")}
              </Button>
            </Flex>
          </Card>
        </GridItem>
        {renderAnalysisCard(
          t("quality"),
          project.analysis?.quality_score || 0,
          FiCheckCircle,
          project.analysis?.quality_suggestions || "",
        )}
        {renderAnalysisCard(
          t("seo"),
          project.analysis?.seo_score || 0,
          FiSearch,
          project.analysis?.seo_suggestions || "",
        )}
        {renderAnalysisCard(
          t("spelling"),
          project.analysis?.spelling_score || 0,
          FiEdit,
          project.analysis?.spelling_suggestions || "",
        )}
        {renderAnalysisCard(
          t("marketing"),
          project.analysis?.marketing_score || 0,
          FiTrendingUp,
          project.analysis?.marketing_suggestions || "",
        )}
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("confirmAnalysis")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t("analyzeCostConfirmation")}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={() => {
                confirmAnalyzeProject();
                onClose();
              }}
            >
              {t("yesAnalyze")}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Analyse;
