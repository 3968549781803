import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Switch,
  Text,
  DarkMode,
  Spinner,
  useToast,
  Link,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom"; // Si tu utilises react-router
import { useAuth } from "../context/AuthContext";
import signInImage from "../assets/img/signInImage.png";
import AuthFooter from "../components/Footer/AuthFooter";
import GradientBorder from "../components/GradientBorder/GradientBorder";
import { FaGoogle } from "react-icons/fa";
import bmLogo from "../assets/svg/bm_logo.svg"; // Import the SVG image
import { useTranslation } from "react-i18next"; // Importer useTranslation
import i18n from "../i18n"; // Importer i18n pour changer la langue

const Login = () => {
  const titleColor = "white";
  const textColor = "gray.400";
  const toast = useToast();
  const { t } = useTranslation(); // Utiliser useTranslation pour accéder aux traductions

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isLanguageSet, setIsLanguageSet] = useState(false);

  const { loginWithGoogle, loginWithEmail, signupWithEmail, loading } =
    useAuth();

  const location = useLocation();

  useEffect(() => {
    if (isLanguageSet) return; // Si la langue est déjà définie, ne rien faire

    // Récupérer le paramètre 'lang' dans l'URL
    const params = new URLSearchParams(location.search);
    const urlLanguage = params.get("lang");

    // Récupérer le code langue du navigateur
    const userLanguage = navigator.language;
    const browserLanguageCode = userLanguage.split("-")[0]; // e.g., 'fr' à partir de 'fr-FR'

    // Priorité au code de langue passé dans l'URL, sinon utiliser celui du navigateur
    const languageCode = urlLanguage || browserLanguageCode;

    // Si le code est dans la liste des langues supportées, changer la langue
    if (
      ["fr", "en", "es", "it", "de", "jp"].includes(
        String(languageCode).toLowerCase(),
      )
    ) {
      i18n
        .changeLanguage(String(languageCode).toLowerCase())
        .then(() => {
          console.log(`Langue changée en: ${languageCode}`);
          setIsLanguageSet(true); // Marquer la langue comme étant définie
        })
        .catch((err) => {
          console.error("Erreur lors du changement de langue:", err);
        });
    } else {
      setIsLanguageSet(true); // Si la langue n'est pas supportée, marquer comme définie
    }
  }, [location, isLanguageSet]);

  const handleGoogleLogin = async () => {
    setError(null);
    try {
      await loginWithGoogle();
      console.log(t("loggedInWithGoogle"));
    } catch (error) {
      setError(t("failedToLogInWithGoogle"));
    }
  };

  const handleLogin = async () => {
    setError(null);
    try {
      await loginWithEmail(email, password);
      console.log(t("loggedInWithEmailAndPassword"));
    } catch (error) {
      setError(t("failedToLogIn"));
    }
  };

  const handleSignup = async () => {
    setError(null);
    try {
      await signupWithEmail(email, password);
      console.log(t("accountCreated"));
    } catch (error) {
      setError(t("failedToCreateAccount"));
    }
  };

  if (loading) {
    return (
      <Flex
        align="center"
        justify="center"
        minH="100vh"
        bg="gray.800"
        color="white"
      >
        <Spinner size="xl" />
        <Text mt={4} ml={4}>
          {t("loading")}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex position="relative">
      <Flex
        minH="30vh"
        h={{ base: "30vh", lg: "fit-content" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: "100px", md: "0px" }}
        flexDirection="column"
        me={{ base: "auto", lg: "50px", xl: "auto" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          w={{ base: "100%", md: "50%", lg: "450px" }}
          px="50px"
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: "50px", md: "150px", lg: "50px", xl: "50px" }}
            mb={{ base: "60px", lg: "95px" }}
            alignItems="center"
          >
            <Box alignItems="left">
              <img
                src={bmLogo}
                alt="BM Logo"
                width="200px"
                style={{ marginRight: "10px", marginTop: "2px" }}
              />
              <Heading
                className="title"
                color={titleColor}
                fontSize="32px"
                mb="10px"
              >
                {t("welcome")}
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                className="subtitle"
                color={textColor}
                fontWeight="bold"
                fontSize="14px"
              >
                {t("pleaseSignInOrSignUp")}
                <br />
                {t("trialIsFree")}
              </Text>
            </Box>
            {error && <Text color="red.500">{error}</Text>}
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                color="white"
              >
                {t("email")}
              </FormLabel>
              <GradientBorder
                mb="24px"
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius="20px"
              >
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  size="lg"
                  w={{ base: "100%", md: "346px" }}
                  maxW="100%"
                  h="46px"
                  placeholder={t("emailPlaceholder")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </GradientBorder>
            </FormControl>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                color="white"
              >
                {t("password")}
              </FormLabel>
              <GradientBorder
                mb="24px"
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius="20px"
              >
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  size="lg"
                  w={{ base: "100%", md: "346px" }}
                  maxW="100%"
                  type="password"
                  placeholder={t("passwordPlaceholder")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </GradientBorder>
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <DarkMode>
                <Switch id="remember-login" colorScheme="brand" me="10px" />
              </DarkMode>
              <FormLabel
                htmlFor="remember-login"
                mb="0"
                ms="1"
                fontWeight="normal"
                color="white"
              >
                {t("rememberMe")}
              </FormLabel>
            </FormControl>
            <Button
              colorScheme="teal"
              fontSize="15px"
              type="button"
              w="100%"
              maxW="350px"
              h="45"
              mb="20px"
              mt="20px"
              onClick={handleLogin}
            >
              {t("signIn")}
            </Button>
            <Button
              background={"#E003BD"}
              fontSize="15px"
              w="100%"
              maxW="350px"
              h="45"
              mb="20px"
              onClick={handleSignup}
            >
              {t("signUp")}
            </Button>
            <Button
              border="1px solid white"
              variant="brand"
              fontSize="10px"
              w="100%"
              maxW="350px"
              h="45"
              mb="20px"
              onClick={handleGoogleLogin}
              colorScheme="red"
            >
              <FaGoogle size="15pt" />
              &nbsp;{t("signInWithGoogle")}
            </Button>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            ></Flex>
          </Flex>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb="80px"
        >
          <AuthFooter />
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: "50vw", lg: "50vw" }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500"
            >
              {t("levelUp")}
            </Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)"
            >
              {t("withBulletMonkey")}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
