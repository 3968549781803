import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PublicRoute = ({ children }: { children: JSX.Element }) => {
    const { currentUser, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>; // Ou un spinner pour indiquer le chargement
    }

    return currentUser ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
