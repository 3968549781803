import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useAuth } from "../context/AuthContext"; // Importer useAuth

interface NavbarContextProps {
  credits: number;
  updateCredits: () => void;
}

const NavbarContext = createContext<NavbarContextProps | undefined>(undefined);

export const NavbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [credits, setCredits] = useState<number>(0);
  const { currentUser, getToken } = useAuth(); // Utiliser useAuth pour obtenir currentUser

  const updateCredits = async () => {
    try {
      const token = await getToken();
      const response = await fetch("/.netlify/functions/getUserCredits", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Utiliser currentUser
        },
        body: JSON.stringify({
          userId: currentUser?.uid, // Utiliser currentUser
        }),
      });

      const result = await response.json();
      if (response.status === 200) {
        setCredits(result.credits);
      }
    } catch (error) {
      console.error("Failed to update credits", error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      updateCredits();
    }
  }, [currentUser]);

  return (
    <NavbarContext.Provider value={{ credits, updateCredits }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error("useNavbar must be used within a NavbarProvider");
  }
  return context;
};
