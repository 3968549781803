import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  Text,
  Card,
  CardHeader,
  CardBody,
  Image,
  SimpleGrid,
  Progress,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import SingeDebutant from "../assets/img/SingeDebutant.png";
import SingeExpert from "../assets/img/SingeCartonsFinal.png";

interface MarketplaceOption {
  value: string;
  label: string;
  flag: FlagIconCode;
}

const CreateProjectCard: React.FC = () => {
  const { currentUser, getToken } = useAuth();
  const { t } = useTranslation();
  const [projectType, setProjectType] = useState<"asin" | "noAsin" | null>(
    null,
  );
  const [noAsin, setNoAsin] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const [asin, setAsin] = useState<string>("");
  const [marketplace, setMarketplace] = useState<MarketplaceOption>({
    value: "fr",
    label: "Amazon.fr",
    flag: "FR",
  });
  const [asinError, setAsinError] = useState<string | null>(null);
  const [showSelection, setShowSelection] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const navigate = useNavigate();

  const extractAsinFromUrl = (url: string) => {
    const match = url.match(
      /(?:dp|gp\/product|gp\/offer-listing)\/([A-Z0-9]{10})/,
    );
    if (match) {
      detectMarketplace(url);
    }
    return match ? match[1] : null;
  };

  const handleProjectTypeSelection = (type: "asin" | "noAsin") => {
    setProjectType(type);
    setShowSelection(false);
    if (type === "noAsin") {
      setNoAsin(true);
    } else {
      setNoAsin(false);
    }
  };

  const handleBack = () => {
    setProjectType(null);
    setShowSelection(true);
    setProjectName("");
    setAsin("");
    setAsinError(null);
  };

  const detectMarketplace = (url: string) => {
    const domain = url.match(/https?:\/\/(www\.)?([^\/]+)/i);
    if (domain && domain[2]) {
      const domainMap: Record<string, MarketplaceOption> = {
        "amazon.fr": { value: "fr", label: "Amazon.fr", flag: "FR" },
        "amazon.it": { value: "it", label: "Amazon.it", flag: "IT" },
        "amazon.es": { value: "es", label: "Amazon.es", flag: "ES" },
        "amazon.de": { value: "de", label: "Amazon.de", flag: "DE" },
        "amazon.se": { value: "se", label: "Amazon.se", flag: "SE" },
        "amazon.ch": { value: "ch", label: "Amazon.ch", flag: "CH" },
        "amazon.com": { value: "us", label: "Amazon.com", flag: "US" },
      };

      if (domainMap[domain[2]]) {
        setMarketplace(domainMap[domain[2]]);
      }
    }
  };

  const handleCreateProject = async () => {
    if (projectType === "asin" && asin) {
      let extractedAsin: string | null = asin;

      if (asin.startsWith("http")) {
        extractedAsin = extractAsinFromUrl(asin);
        if (!extractedAsin) {
          setAsinError(t("invalidAmazonUrl"));
          return;
        }
      }

      const asinRegex = /^[A-Z0-9]{10}$/;
      if (!asinRegex.test(extractedAsin)) {
        setAsinError(t("invalidAsin"));
        return;
      }

      setAsin(extractedAsin);
    }

    setAsinError(null);
    setLoading(true);
    setProgress(10);

    if (currentUser) {
      try {
        const token = await getToken();
        const response = await fetch("/.netlify/functions/createProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser.uid,
            projectName,
            asin: projectType === "asin" ? asin : null,
            noAsin: projectType === "noAsin",
            marketplace: marketplace.value,
          }),
        });

        if (response.status === 202) {
          setProgress(20);
        }

        const data = await response.json();

        if (data?.projectId) {
          setProgress(30);
          pollForProjectStatus(data.projectId);
        } else {
          console.error("Project creation failed:", data);
          toast.error(t("projectCreationFailed"));
        }
      } catch (error) {
        console.error("Error creating project:", error);
        toast.error(t("projectCreationError"));
        setLoading(false);
      }
    }
  };

  const pollForProjectStatus = async (projectId: string) => {
    const token = await getToken();

    const intervalId = setInterval(async () => {
      try {
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const data = await response.json();
        const { project = {} } = data;
        if (project?.building === true) {
          setProgress(70);
        }

        if (project?.building === false) {
          setProgress(100);
          clearInterval(intervalId);
          setTimeout(() => navigate(`/projects/${projectId}`), 500);
        } else if (Date.now() - project.createdAt > 8000) {
          setProgress(80);
        }
      } catch (error) {
        console.error("Error polling project status:", error);
        clearInterval(intervalId);
        setLoading(false);
      }
    }, 500);
  };

  const countryOptions: MarketplaceOption[] = [
    { value: "fr", label: "Amazon.fr", flag: "FR" },
    { value: "it", label: "Amazon.it", flag: "IT" },
    { value: "es", label: "Amazon.es", flag: "ES" },
    { value: "de", label: "Amazon.de", flag: "DE" },
    { value: "se", label: "Amazon.se", flag: "SE" },
    { value: "ch", label: "Amazon.ch", flag: "CH" },
    { value: "us", label: "Amazon.com", flag: "US" },
    { value: "jp", label: "Amazon.co.jp", flag: "JP" },
  ];

  const customStyles = {
    control: (base: any) => ({
      ...base,
      background: "#0F1535",
      color: "#fff",
      borderRadius: "8px",
      borderColor: "transparent",
      boxShadow: "none",
    }),
    option: (base: any, { isFocused }: { isFocused: boolean }) => ({
      ...base,
      backgroundColor: isFocused ? "#1A202C" : "#0F1535",
      color: "#fff",
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: "#0F1535",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#fff",
    }),
  };

  const formatOptionLabel = ({ label, flag }: MarketplaceOption) => (
    <Flex alignItems="center">
      <FlagIcon code={flag} size={20} style={{ marginRight: 10 }} />
      <span>{label}</span>
    </Flex>
  );

  const handleAsinInput = (value: string) => {
    const asin = extractAsinFromUrl(value);
    console.log({ asin });
    setAsin(value);
    if (asin) setAsin(asin);
  };

  const handleAsinInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!projectName || (!asin && !noAsin) || loading) return;
      handleCreateProject();
    }
  };

  return (
    <Box m={0} p={0}>
      {showSelection ? (
        <SimpleGrid columns={{ base: 1, md: 2 }} p={0} spacing={10}>
          <Card
            p={0}
            onClick={() => handleProjectTypeSelection("noAsin")}
            cursor="pointer"
            _hover={{ transform: "scale(1.05)" }}
            transition="all 0.2s"
            className="dashcard creator"
          >
            <CardHeader
              style={{ position: "relative" }}
              display="flex"
              alignItems="center"
              p={0}
            >
              <Image src={SingeDebutant} alt="No ASIN Project" />
            </CardHeader>
            <CardBody className="productLabel" p={0}>
              <Text fontSize="xl" fontWeight="bold" className="productTitle">
                {t("projectWithoutAsin")}
              </Text>
              <Text>{t("projectWithoutAsinDescription")}</Text>
            </CardBody>
          </Card>
          <Card
            p={0}
            onClick={() => handleProjectTypeSelection("asin")}
            cursor="pointer"
            _hover={{ transform: "scale(1.05)" }}
            transition="all 0.2s"
            className="dashcard creator"
          >
            <CardHeader
              style={{ position: "relative" }}
              display="flex"
              alignItems="center"
              p={0}
            >
              <Image src={SingeExpert} alt="ASIN Project" />
            </CardHeader>
            <CardBody className="productLabel" p={0}>
              <Text fontSize="xl" fontWeight="bold" className="productTitle">
                {t("projectWithAsin")}
              </Text>
              <Text>{t("projectWithAsinDescription")}</Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      ) : (
        <Flex
          maxW="lg"
          mx="auto"
          p={10}
          borderWidth="1px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          background="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)"
          borderRadius="20px"
          boxShadow="0px 5px 20px rgba(0, 0, 0, 0.15)"
        >
          {" "}
          <Box mt={0}>
            <Button
              leftIcon={<ChevronLeftIcon />}
              variant="outline"
              onClick={handleBack}
              alignSelf="flex-start"
              mb={4}
            >
              {t("back")}
            </Button>
            <FormControl>
              <Text fontSize="xl" mb="4" color="white">
                {t("createYourProject")}
                <FormHelperText>{t("internalNameForProject")}</FormHelperText>
              </Text>
              <Input
                placeholder={t("projectNamePlaceholder")}
                value={projectName}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleCreateProject();
                }}
                onChange={(e) => setProjectName(e.target.value)}
                mb="4"
                backgroundColor="#1A202C"
                color="white"
                borderColor="transparent"
                _placeholder={{ color: "gray.400" }}
              />
            </FormControl>

            {projectType === "asin" && (
              <Input
                placeholder={t("asinOrAmazonUrl")}
                value={asin}
                onKeyUpCapture={handleAsinInputKeyUp}
                onChange={(e) => handleAsinInput(e.target.value)}
                isDisabled={noAsin}
                mb="4"
                backgroundColor="#1A202C"
                color="white"
                borderColor={asinError ? "red.500" : "transparent"}
                _placeholder={{ color: "gray.400" }}
              />
            )}
            {asinError && (
              <Text color="red.500" mb="4">
                {asinError}
              </Text>
            )}

            <FormControl>
              <Select
                options={countryOptions}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
                value={marketplace}
                onChange={(option) =>
                  setMarketplace(option as MarketplaceOption)
                }
                isSearchable={false}
              />
              <FormHelperText>{t("addOtherMarketplacesLater")}</FormHelperText>
            </FormControl>

            {loading && (
              <Progress value={progress} size="xs" colorScheme="teal" mt="4" />
            )}

            <Button
              colorScheme="teal"
              onClick={handleCreateProject}
              isDisabled={
                !projectName || (projectType === "asin" && !asin) || loading
              }
              mt="4"
            >
              {loading ? <Spinner size="sm" /> : t("createProject")}
            </Button>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CreateProjectCard;
