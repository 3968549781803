import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { currentUser, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>; // Ou un spinner pour indiquer le chargement
    }

    return currentUser ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
