import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  useToast,
  Textarea,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import { useAuth } from "../context/AuthContext";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import FloatingBox from "./FloatingBox";
import { FaCheckCircle, FaPenAlt, FaSyncAlt, FaCopy } from "react-icons/fa";
import GenerateExcelFile from "./GenerateExcelFile";
import { useTranslation } from "react-i18next"; // Ajout de l'import pour la traduction

const textColor = "white";

interface BulletCreatorProps {
  projectId: string | undefined;
  description: string;
}

interface SortableItemProps {
  id: string;
  index: number;
  text: string;
  onSave: (index: number, updatedText: string) => void;
  isEditing: boolean;
  setEditing: (index: number) => void;
  userId?: string | undefined;
  projectId?: string | undefined;
  bullets: string[];
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  index,
  text,
  onSave,
  isEditing,
  setEditing,
  userId,
  projectId,
  bullets,
}) => {
  const { t } = useTranslation(); // Utilisation de la traduction
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [localText, setLocalText] = useState<string>(text);
  const [selectedText, setSelectedText] = useState<string>("");
  const [showHelper, setShowHelper] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const toast = useToast();

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
        setSelectedText("");
        onSave(index, localText);
        setEditing(-1);
      }
      if (e.key === "Escape") {
        setEditing(-1);
      }
    },
    [index, localText, onSave, setEditing],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSelectedText("");
      setLocalText(e.target.value);
    },
    [],
  );

  const handleBlur = useCallback(() => {
    setShowHelper(false);
  }, []);

  const handleFocus = useCallback(() => {
    setShowHelper(false);
    setTimeout(() => {
      if (document.activeElement === textareaRef.current) {
        setShowHelper(true);
      }
    }, 2500);
  }, []);

  const handleRephrase = async (text: string, advancedInstructions: string) => {
    try {
      const response = await fetch("/.netlify/functions/rephrase", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
          userId,
          text: selectedText,
          advancedInstructions,
          language: "français", // Cette ligne doit être dynamique si nécessaire
        }),
      });

      if (!response.ok) {
        throw new Error(t("error"));
      }

      const result = await response.json();
      const reformulatedText = result.reformulatedText;

      const textarea = textareaRef.current;
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const before = textarea.value.substring(0, start);
        const after = textarea.value.substring(end);
        setLocalText(before + reformulatedText + after);
      }

      toast({
        title: t("rephrasedSuccessfully"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("rephraseError"),
        description: t("noBetterRephraseFound"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelection = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      const selectedText = textarea.value.substring(
        textarea.selectionStart,
        textarea.selectionEnd,
      );
      if (selectedText) {
        setSelectedText(selectedText);
      } else {
        setSelectedText("");
      }
    }
  };

  const handleGenerateSingleBullet = async () => {
    setIsRefreshing(true);

    try {
      const response = await fetch(
        "/.netlify/functions/generateSingleBulletListing",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            userId,
            index,
            currentBullet: localText,
            allBullets: bullets,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(t("error"));
      }

      const result = await response.json();
      const newBullet = result.generatedBullet;

      setLocalText(newBullet);
      onSave(index, newBullet);

      toast({
        title: t("bulletRewritten"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("generationError"),
        description: t("generationErrorDescription"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(localText).then(() => {
      toast({
        title: t("copied"),
        description: t("bulletCopiedToClipboard"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isEditing ? "text" : "grab",
    display: "inline-block",
    marginRight: "10px",
  };

  return (
    <Box position="relative">
      <li
        className="bulletHolder"
        ref={setNodeRef}
        style={style}
        {...(isEditing ? {} : listeners)}
        {...attributes}
      >
        {isEditing ? (
          <>
            <FloatingBox
              text={selectedText}
              onRephrase={handleRephrase}
              onAdvancedRephrase={handleRephrase}
            />

            <Textarea
              ref={textareaRef}
              value={localText}
              onMouseMove={(e) => {
                handleSelection();
              }}
              onKeyUpCapture={() => setSelectedText("")}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onMouseUp={handleSelection}
              rows={10}
              autoFocus
            />

            {showHelper && (
              <Text fontSize="sm" p="2" color="gray.500">
                {t("tip")} :{" "}
                {navigator.platform.startsWith("Mac") ? (
                  <kbd>Cmd</kbd>
                ) : (
                  <kbd>Ctrl</kbd>
                )}{" "}
                + <kbd>{t("enter")}</kbd> {t("toSaveQuickly")}.
              </Text>
            )}
          </>
        ) : (
          <Flex align="center" justify="space-between">
            <Text>{text}</Text>
          </Flex>
        )}
        {isRefreshing && (
          <Box
            position="absolute"
            top="0"
            style={{ borderRadius: "30px" }}
            left="0"
            right="0"
            bottom="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="rgba(0, 0, 0, 0.5)"
            zIndex="999"
          >
            <Spinner size="lg" color="teal.500" />
          </Box>
        )}
      </li>
      <Button
        style={{ opacity: isRefreshing ? 0.5 : 1 }}
        size="xs"
        onClick={(e) => {
          if (isRefreshing) return;
          e.stopPropagation();
          handleGenerateSingleBullet();
        }}
      >
        <FaSyncAlt />
      </Button>
      <Button
        style={{ opacity: isRefreshing ? 0.5 : 1 }}
        size="xs"
        onClick={(e) => {
          if (isRefreshing) return;
          e.stopPropagation();
          setEditing(index);
        }}
      >
        <FaPenAlt />
      </Button>
      <Button
        style={{ opacity: isRefreshing ? 0.5 : 1 }}
        size="xs"
        onClick={(e) => {
          if (isRefreshing) return;
          e.stopPropagation();
          handleCopyToClipboard();
        }}
      >
        <FaCopy />
      </Button>
      {isEditing && (
        <Button
          style={{ opacity: isRefreshing ? 0.5 : 1 }}
          size="xs"
          onClick={(e) => {
            if (isRefreshing) return;
            e.stopPropagation();
            onSave(index, localText);
            setEditing(-1);
          }}
        >
          <FaCheckCircle />
        </Button>
      )}
    </Box>
  );
};

const BulletCreator: React.FC<BulletCreatorProps> = ({
  projectId,
  description,
}) => {
  const { t } = useTranslation(); // Utilisation de la traduction
  const { currentUser } = useAuth();
  const [bullets, setBullets] = useState<string[]>([]);
  const [editingIndex, setEditingIndex] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const toast = useToast();
  const dialog = useDisclosure();

  useEffect(() => {
    if (description.length > 2) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [description]);

  useEffect(() => {
    const fetchBullets = async () => {
      try {
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const result = await response.json();

        if (response.status === 200 && result.project?.generatedBullet) {
          setBullets(result.project.generatedBullet);
        }
      } catch (error) {
        console.error(t("errorFetchingBullets"), error);
      }
    };

    fetchBullets();
  }, [currentUser, projectId, t]);

  const handleGenerateBullets = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "/.netlify/functions/generateBulletListing",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            userId: currentUser?.uid,
            description,
          }),
        },
      );

      if (response.status === 200) {
        pollForGeneratedBullets();
        toast({
          title: t("generationStarted"),
          description: t("bulletsWillBeAvailableSoon"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(t("errorStartingBulletGeneration"));
      }
    } catch (error) {
      console.error(t("generationError"), error);
      toast({
        title: t("error"),
        description: t("generationFailed"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const pollForGeneratedBullets = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const result = await response.json();

        if (response.status === 200 && result.project?.generatedBullet) {
          setBullets(result.project.generatedBullet);
          clearInterval(intervalId);
          setLoading(false);
          toast({
            title: t("generationComplete"),
            description: t("bulletsAvailable"),
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error(t("errorFetchingUpdatedBullets"), error);
        clearInterval(intervalId);
        setLoading(false);
      }
    }, 3500);

    // Timeout to stop polling after a certain time
    setTimeout(() => {
      clearInterval(intervalId);
      setLoading(false);
    }, 20000); // 1 minute timeout
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = bullets.indexOf(active.id);
      const newIndex = bullets.indexOf(over.id);

      const updatedBullets = arrayMove(bullets, oldIndex, newIndex);
      setBullets(updatedBullets);

      await saveBullets(updatedBullets);
    }
  };

  const handleSaveBullet = (index: number, updatedText: string) => {
    setBullets((prevBullets) => {
      const updatedBullets = [...prevBullets];
      updatedBullets[index] = updatedText;

      saveBullets(updatedBullets);
      return updatedBullets;
    });
  };

  const saveBullets = async (updatedBullets: string[]) => {
    try {
      const response = await fetch("/.netlify/functions/saveGeneratedBullets", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
          userId: currentUser?.uid,
          generatedBullet: updatedBullets,
        }),
      });

      if (!response.ok) {
        throw new Error(t("errorSavingBullets"));
      }
    } catch (error) {
      toast({
        title: t("error"),
        description: t("bulletSavingFailed"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(t("errorSavingBullets"), error);
    }
  };

  return (
    <Box position="relative" mt={4}>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        style={{ borderRadius: "30px" }}
        bg="rgba(0, 0, 0, 0.5)"
        display={isDisabled ? "block" : "none"}
        pointerEvents="none"
        transition="opacity 0.5s ease"
        opacity={isDisabled ? 1 : 0}
        zIndex="10"
      />
      <Card
        p="1rem"
        style={{ marginTop: "30px" }}
        pointerEvents={isDisabled ? "none" : "auto"}
        opacity={isDisabled ? 0.5 : 1}
        transition="opacity 0.5s ease"
      >
        <CardHeader p="5px" mb="12px" style={{ width: "100%" }}>
          <Flex justify="space-between" align="center">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {t("bulletListing")}
            </Text>
            <Button
              onClick={handleGenerateBullets}
              colorScheme="teal"
              isLoading={loading}
              isDisabled={loading || isDisabled}
            >
              {loading ? t("loading") : t("generate")}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          {bullets.length === 0 ? (
            <Text color="yellow.500" fontSize="xl" textAlign="center">
              {loading ? t("waitingForGeneration") : t("clickToGenerate")}
            </Text>
          ) : (
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={bullets}
                strategy={horizontalListSortingStrategy}
              >
                <ul className="bullets">
                  {bullets.map((bullet, index) => (
                    <SortableItem
                      key={bullet}
                      bullets={bullets}
                      id={bullet}
                      index={index}
                      text={bullet}
                      onSave={handleSaveBullet}
                      isEditing={editingIndex === index}
                      setEditing={setEditingIndex}
                      userId={currentUser?.uid}
                      projectId={projectId}
                    />
                  ))}
                </ul>
              </SortableContext>
            </DndContext>
          )}
        </CardBody>
      </Card>
      <GenerateExcelFile projectId={projectId} bullets={bullets} />
    </Box>
  );
};

export default BulletCreator;
