import React from "react";
import { DarkMode } from "@chakra-ui/react";
import PublicNavbar from "../components/Navbar/PublicNavbar";
import PublicFooter from "../components/Footer/PublicFooter";

const PublicLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <DarkMode>{children}</DarkMode>;
};

export default PublicLayout;
