import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Heading,
  Text,
  VStack,
  HStack,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  useToast,
  Spinner,
  Link,
  SimpleGrid,
  Input,
} from "@chakra-ui/react";
import { FaCheckCircle, FaFilePdf, FaTimesCircle } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../context/AuthContext";
import { format } from "date-fns";
import imageMonkey from "../assets/img/creditMonkey.png";
import svgCreditIcon from "../assets/svg/credits.svg";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const Subscribe = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState<any>(null);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(false);
  const toast = useToast();
  const { t } = useTranslation();

  const [promoCode, setPromoCode] = useState("");

  const handlePromoCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch("/.netlify/functions/apply-promo-code", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: currentUser?.uid, promoCode }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: t("promoCodeApplied"),
          description: t("creditsAdded", { count: data.creditsAdded }),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setPromoCode("");
      } else {
        toast({
          title: t("promoCodeError"),
          description: data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      toast({
        title: t("promoCodeError"),
        description: t("unexpectedError"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("/.netlify/functions/get-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: currentUser?.uid }),
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoadingInvoices(true);
      try {
        const response = await fetch("/.netlify/functions/getInvoices", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: currentUser?.uid }),
        });

        if (response.ok) {
          const data = await response.json();
          setInvoices(data);
        } else {
          console.error("Failed to fetch invoices");
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setLoadingInvoices(false);
      }
    };

    fetchInvoices();
  }, [currentUser]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get("session_id");
    if (sessionId) {
      toast({
        title: "Paiement réussi",
        description: "Votre paiement a été traité avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  const handleSubscription = async (plan: string) => {
    if (!currentUser || !currentUser.uid) {
      console.error("User is not authenticated or UID is missing.");
      return;
    }

    const stripe = await stripePromise;

    const response = await fetch(
      "/.netlify/functions/create-checkout-session",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          plan,
          userId: currentUser.uid,
        }),
      },
    );

    const { sessionId } = await response.json();

    if (!stripe) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    const result = await stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      console.error(
        "Error redirecting to Stripe Checkout:",
        result.error.message,
      );
    }
  };

  const formatDate = (timestamp: any) => {
    if (!timestamp || !timestamp._seconds) {
      return "Date non disponible";
    }
    return format(new Date(timestamp._seconds * 1000), "dd MMM yyyy");
  };

  return (
    <Box py={12} mt={-15}>
      <SimpleGrid alignContent={"left"}>
        <Box p={0} w="80%" margin="0 auto" textAlign={"center"}>
          <img
            src={imageMonkey}
            style={{ height: "280px", margin: "0 auto" }}
            alt="credits"
          />
          <VStack spacing={2} textAlign="left">
            <Heading as="h1" fontSize="4xl">
              {t("manageYourSubscription")}
            </Heading>
            <Text
              fontSize="lg"
              color={useColorModeValue("gray.600", "gray.400")}
            >
              {t("viewAndManageSubscription")}
            </Text>
          </VStack>
          {userData && (
            <Box textAlign="center" mt={5}>
              <Text fontSize="xl" fontWeight="bold">
                {t("currentPlan")} {userData.plan}
              </Text>
              <Text>
                {t("startDate")} {formatDate(userData.startSubscriptionDate)}
              </Text>
              <Text>
                {t("endDate")} {formatDate(userData.endSubscriptionDate)}
              </Text>
            </Box>
          )}
        </Box>
      </SimpleGrid>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        {/* Abonnement Mensuel */}
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={8}
          shadow="base"
          alignSelf={{ base: "center", lg: "flex-start" }}
          opacity={userData?.plan === "monthly" ? 0.5 : 1}
          className={userData?.plan === "monthly" ? "monthly disabled" : ""}
        >
          <VStack spacing={3}>
            <Heading as="h3" size="md">
              {t("monthlySubscription")}
            </Heading>
            <HStack justify="center">
              <Text fontSize="3xl" fontWeight="600">
                50€
              </Text>
              <Text fontSize="xl" color="gray.500">
                {t("perMonth")}
              </Text>
            </HStack>
            <Text
              fontSize="sm"
              color={useColorModeValue("gray.600", "gray.400")}
            >
              <img
                src={svgCreditIcon}
                alt="{credits}"
                width="20px"
                className={"credit-icon"}
              />{" "}1000{" "}
              {t("creditsPerMonth")}
            </Text>
            <Button
              colorScheme="teal"
              onClick={() => handleSubscription("monthly")}
              isDisabled={userData?.plan === "monthly"}
            >
              {userData?.plan === "monthly" ? t("currentPlan") : t("choosePlan")}
            </Button>
            <List spacing={3} textAlign="start">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("unlimitedAccess")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("additionalAsinPerMonth")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("prioritySupport")}
              </ListItem>
            </List>
          </VStack>
        </Box>

        {/* Abonnement Annuel */}
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={8}
          shadow="base"
          alignSelf={{ base: "center", lg: "flex-start" }}
          bg={useColorModeValue("teal.100", "teal.900")}
          className={userData?.plan === "annual" ? "annual disabled" : ""}
        >
          <VStack spacing={3}>
            <Heading as="h3" size="md">
              {t("annualSubscription")}
            </Heading>
            <HStack justify="center">
              <Text fontSize="3xl" fontWeight="600">
                360€
              </Text>
              <Text fontSize="xl" color="gray.500">
                {t("perYear")}
              </Text>
            </HStack>
            <Text
              fontSize="sm"
              color={useColorModeValue("gray.600", "gray.400")}
            >
              <img
                  src={svgCreditIcon}
                  alt="{credits}"
                  width="20px"
                  className={"credit-icon"}
              />{" "}2500{" "}{t("creditsPerMonth")}
            </Text>
            <Button
              colorScheme="teal"
              onClick={() => handleSubscription("annual")}
              isDisabled={userData?.plan === "annual"}
            >
              {userData?.plan === "annual"
                ? t("currentPlan")
                : t("switchToAnnual")}
            </Button>
            <List spacing={3} textAlign="start">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("annualDiscount")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("unlimitedAsins")}
              </ListItem>{" "}
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("unlimitedTranslations")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("premiumSupport")}
              </ListItem>
            </List>
          </VStack>
        </Box>

        {/* Pay As You Go */}
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={8}
          shadow="base"
          alignSelf={{ base: "center", lg: "flex-start" }}
        >
          <VStack spacing={3}>
            <Heading as="h3" size="md">
              {t("payAsYouGo")}
            </Heading>
            <HStack justify="center">
              <Text fontSize="3xl" fontWeight="600">
                30€
              </Text>
              <Text fontSize="xl" color="gray.500">
                {t("oneTimePayment")}
              </Text>
            </HStack>
            <Text
              fontSize="sm"
              color={useColorModeValue("gray.600", "gray.400")}
            >
              <img
                  src={svgCreditIcon}
                  alt="{credits}"
                  width="20px"
                  className={"credit-icon"}
              />{" "}1000{" "}
              {t("creditsPerPurchase")}
            </Text>
            <Button
              colorScheme="teal"
              onClick={() => handleSubscription("pay-as-you-go")}
            >
              {t("buyCredits")}
            </Button>
            <List spacing={3} textAlign="start">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("creditsUsableAnytime")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("noCommitment")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {t("additionalAsinPerPayment")}
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.200" />
                {t("translationsNotIncluded")}
              </ListItem>
            </List>
          </VStack>
        </Box>
      </Stack>

      {/* Ajout du formulaire pour le code promo */}
      <Box mt={8} w={{ base: "80%", md: "40%" }} margin="0 auto">
        <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
          {t("applyPromoCode")}
        </Heading>
        <form onSubmit={handlePromoCodeSubmit}>
          <HStack>
            <Input
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder={t("enterPromoCode")}
            />
            <Button type="submit" colorScheme="teal">
              {t("apply")}
            </Button>
          </HStack>
        </form>
      </Box>

      {/* Historique de facturation */}
      <Box mt={8}>
        <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
          {t("billingHistory")}
        </Heading>
        {loadingInvoices ? (
          <Spinner />
        ) : invoices.length > 0 ? (
          <Stack spacing={4}>
            {invoices.map((invoice) => (
              <Box
                key={invoice.id}
                p={5}
                shadow="md"
                borderWidth="1px"
                position="relative"
              >
                <Text>
                  <strong>{t("date")}</strong> {formatDate(invoice.created * 1000)}
                </Text>
                <Text>
                  <strong>{t("amount")}</strong> {invoice.amount_paid / 100}{" "}
                  {invoice.currency.toUpperCase()}
                </Text>
                <Text>
                  <strong>{t("status")}</strong> {invoice.status}
                </Text>
                {invoice.invoice_pdf && (
                  <Link
                    href={invoice.invoice_pdf}
                    isExternal
                    position="absolute"
                    right={5}
                    top={5}
                  >
                    <FaFilePdf size="20px" />
                  </Link>
                )}
              </Box>
            ))}
          </Stack>
        ) : (
          <Text textAlign="center">{t("noInvoicesAvailable")}</Text>
        )}
      </Box>
    </Box>
  );
};

export default Subscribe;
