import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { useAuth } from "./AuthContext";

interface Project {
  id: string;
  name: string;
  description?: string;
  asin?: string;
  emoji?: string;
  data?: { main_image: { link: string } } | undefined;
  marketplace?: string | undefined;
  originalProjectId?: string | undefined;
}

interface ProjectListContextProps {
  projects: Project[];
  refreshProjects: () => Promise<void>;
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>; // Ajout de setProjects
}

const ProjectListContext = createContext<ProjectListContextProps | undefined>(
  undefined,
);

export const useProjectList = () => {
  const context = useContext(ProjectListContext);
  if (!context) {
    throw new Error("useProjectList must be used within a ProjectListProvider");
  }
  return context;
};

export const ProjectListProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const { currentUser, getToken } = useAuth();

  const refreshProjects = useCallback(async () => {
    if (!currentUser) return;

    const token = await getToken();
    try {
      const response = await fetch(`/.netlify/functions/getProjects`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userId: currentUser.uid }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh projects");
      }

      const data = await response.json();
      setProjects([...data.projects]);
    } catch (error) {
      console.error("Error refreshing projects:", error);
    }
  }, [currentUser, getToken]);

  useEffect(() => {
    if (currentUser) {
      refreshProjects();
    }
  }, [currentUser, refreshProjects]);

  return (
    <ProjectListContext.Provider
      value={{ projects, setProjects, refreshProjects }}
    >
      {children}
    </ProjectListContext.Provider>
  );
};
