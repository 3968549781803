import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Badge,
  Grid,
  GridItem,
  useToast,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaFlag } from "react-icons/fa";

import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import { useAuth } from "../context/AuthContext";
import { useProject } from "../context/ProjectContext";
import KeywordsCreator from "./KeywordsCreator";
import BulletCreator from "./BulletCreator";
import MarketplaceSelector from "./MarketplaceSelector";
import DescriptionCreator from "./DescriptionCreator";
import Analyse from "./Analyse";

const ViewProject: React.FC<{ setProjectName: (name: string) => void }> = ({
  setProjectName,
}) => {
  const { projectId } = useParams<{ projectId: string | undefined }>();
  const { currentUser } = useAuth();
  const { project, setProject, description, setDescription } = useProject();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await fetch("/.netlify/functions/getProject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: currentUser?.uid,
            projectId,
          }),
        });

        const result = await response.json();

        if (response.status !== 200) {
          setError(result.error);
        } else {
          setProject(result.project);
          setProjectName(result.project.name);
          setDescription(result.project.customDescription || "");
        }
      } catch (error) {
        setError("Failed to load project data");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [currentUser, projectId, setProjectName, setProject, setDescription]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!project) {
    return <Text>No project found</Text>;
  }

  const hasAsinData = project.data && project.data.main_image;

  const flatBulletListing = project.data?.feature_bullets
    ? project.data.feature_bullets.join(". ")
    : "";

  return (
    <>
      <Box p="0 10px">
        {hasAsinData ? (
          <>
            <MarketplaceSelector project={{ ...project, id: projectId }} />
            <Grid templateColumns="repeat(10, 1fr)" gap={4}>
              <GridItem colSpan={3}>
                <Card p="1rem">
                  <CardBody p="0px 5px">
                    <img
                      src={
                        project.data.main_image.link.length && typeof project.data.main_image.link === "string"
                          ? project.data.main_image.link
                          : project.data.main_image
                      }
                      alt={project.data.title}
                      height="350px"
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem colSpan={7}>
                <Card p="1rem">
                  <CardHeader p="12px 5px" mb="12px">
                    <Text fontSize="lg" color="white" fontWeight="bold">
                      <Badge>{project.asin}</Badge> - {project.data.title}
                    </Text>
                  </CardHeader>
                  <CardBody p="0px 5px">
                    <Flex direction="column">
                      <Text
                        color="gray.500"
                        fontSize="9.5pt"
                        fontWeight="400"
                        mb="30px"
                      >
                        {project.data.feature_bullets.map((bullet: any) => (
                          <p style={{ color: "#fff", padding: "5px" }}>
                            {bullet}
                          </p>
                        ))}
                      </Text>
                    </Flex>
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
          </>
        ) : (
          <>
            <MarketplaceSelector project={{ ...project, id: projectId }} />

            <DescriptionCreator projectId={projectId} />
          </>
        )}

        {hasAsinData && (
          <Analyse
            project={project}
            projectId={projectId}
            currentUser={currentUser}
            setProject={setProject}
          />
        )}

        <KeywordsCreator
          projectId={projectId}
          description={description || flatBulletListing}
        />
        <BulletCreator
          projectId={projectId}
          description={description || flatBulletListing}
        />
      </Box>
    </>
  );
};

export default ViewProject;
