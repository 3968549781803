import React, { useEffect, useState } from "react";
import {
  SimpleGrid,
  Text,
  Spinner,
  Badge,
  Box,
  Flex,
  IconButton,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import { useProjectList } from "../context/ProjectListContext";
import { useTranslation } from "react-i18next"; // Import i18n hook
import marketplaceFlags from "../countryCodes";

const Dashboard: React.FC = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const { currentUser, getToken } = useAuth();
  const { projects, setProjects, refreshProjects } = useProjectList();
  const [loading, setLoading] = useState<boolean>(true);
  const [editingProjectId, setEditingProjectId] = useState<string | null>(null);
  const [editingProjectName, setEditingProjectName] = useState<string>("");
  const [deletingProjectId, setDeletingProjectId] = useState<string | null>(
    null,
  );
  const { projectFolderId } = useParams<{ projectFolderId: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      if (currentUser) {
        const token = await getToken();
        try {
          const response = await fetch(`/.netlify/functions/getProjects`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ userId: currentUser.uid }),
          });

          if (!response.ok) {
            throw new Error(t("errorFetchProjects"));
          }

          const data = await response.json();
          setProjects(data.projects || []);
        } catch (error) {
          console.error(t("errorFetchProjects"), error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProjects();
  }, [currentUser, getToken, setProjects, t]);

  const truncateDescription = (description: string | undefined) => {
    if (!description) return "";
    return description.length > 20
      ? `${description.slice(0, 20)}...`
      : description;
  };

  const handleEditProject = (projectId: string, newName: string) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, name: newName } : project,
      ),
    );
  };

  const handleDeleteProject = (projectId: string) => {
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== projectId),
    );
  };

  const confirmEditProject = async () => {
    const token = await getToken();
    try {
      const response = await fetch("/.netlify/functions/editProject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          projectId: editingProjectId,
          newName: editingProjectName,
        }),
      });

      if (!response.ok) {
        throw new Error(t("errorEditProject"));
      }

      handleEditProject(editingProjectId!, editingProjectName);
      setEditingProjectId(null); // Reset editing state
    } catch (error) {
      console.error(t("errorEditProject"), error);
    }
  };

  const renderImage = ({
    emoji = "",
    name = "",
    asin = "",
    data = { main_image: { link: "" } },
  }) => {
    if (data) {
      const { main_image = { link: "" } } = data;
      const { link = "" } = main_image;
      if (asin && link) {
        return (
          <Box
            className="productImage"
            height={{
              base: "200px",
              sm: "200px",
              md: "150px",
              lg: "150px",
              xl: "150px",
            }}
          >
            <img
              src={
                typeof data.main_image === 'object' && 'link' in data.main_image
                  ? data.main_image.link
                  : (data.main_image as string) ?? ''
              }
              alt={name}
            />
          </Box>
        );
      }
    }

    return (
      <Box
        className="productEmoji"
        height={{
          base: "200px",
          sm: "200px",
          md: "150px",
          lg: "150px",
          xl: "150px",
        }}
      >
        <Text className="emoji">{emoji}</Text>
      </Box>
    );
  };

  const confirmDeleteProject = async () => {
    const token = await getToken();
    try {
      const response = await fetch("/.netlify/functions/deleteProject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          projectId: deletingProjectId,
        }),
      });

      if (!response.ok) {
        throw new Error(t("errorDeleteProject"));
      }

      handleDeleteProject(deletingProjectId!);
      onClose(); // Close the modal after deletion
    } catch (error) {
      console.error(t("errorDeleteProject"), error);
    }
  };

  const renderProjectList = () => {
    const projectList = [] as JSX.Element[];
    const folderList = [] as JSX.Element[];
    const parentSet = new Set<string>();

    projects.forEach((project) => {
      if (project.originalProjectId) {
        parentSet.add(project.originalProjectId);
      }
    });

    if (projects.length > 0) {
      projects.forEach((project) => {
        if (
          (!parentSet.has(project.id) &&
            !parentSet.has(project.originalProjectId as string)) ||
          projectFolderId
        ) {
          const preparedProjetCard = (
            <Card
              className="dashcard project"
              key={project.id}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              onClick={() => navigate(`/projects/${project.id}`)}
              p={0}
            >
              <CardHeader
                style={{ position: "relative" }}
                display="flex"
                alignItems="center"
              >
                {renderImage(project)}
              </CardHeader>
              <CardBody className="productLabel" p={0}>
                {editingProjectId === project.id ? (
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    value={editingProjectName}
                    onChange={(e) => setEditingProjectName(e.target.value)}
                    onBlur={confirmEditProject}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") setEditingProjectId(null);
                      if (e.key === "Enter") confirmEditProject();
                    }}
                    autoFocus
                  />
                ) : (
                  <Text
                    className="productTitle"
                    fontWeight="bold"
                    cursor="pointer"
                  >
                    {project.name || t("untitledProject")}
                  </Text>
                )}
                <Flex className="buttons" ml="auto">
                  <IconButton
                    aria-label={t("editProject")}
                    icon={<EditIcon />}
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingProjectId(project.id);
                      setEditingProjectName(project.name || "");
                    }}
                    variant="ghost"
                  />
                  <IconButton
                    aria-label={t("deleteProject")}
                    icon={<DeleteIcon />}
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletingProjectId(project.id);
                      onOpen();
                    }}
                    variant="ghost"
                  />
                </Flex>

                <Text className="asin" color="gray.500">
                  <Text className="marketplace" color="gray.500">
                    <Badge>
                      {
                        marketplaceFlags[
                          String(project.marketplace).toLowerCase() || "fr"
                        ]
                      }
                    </Badge>
                  </Text>
                  <Badge>{project.asin || t("creationMode")}</Badge>
                </Text>
              </CardBody>
            </Card>
          );

          if (
            !projectFolderId ||
            projectFolderId === project.originalProjectId ||
            projectFolderId === project.id
          )
            projectList.push(preparedProjetCard);
        } else if (!project.originalProjectId && !projectFolderId) {
          folderList.push(
            <Card
              className="dashcard project folder"
              key={project.id}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              onClick={() => navigate(`/folder/${project.id}`)}
              p={0}
            >
              <CardHeader
                style={{ position: "relative" }}
                display="flex"
                alignItems="center"
              >
                {renderImage(project)}
              </CardHeader>
              <CardBody className="productLabel" p={0}>
                {editingProjectId === project.id ? (
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    value={editingProjectName}
                    onChange={(e) => setEditingProjectName(e.target.value)}
                    onBlur={confirmEditProject}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") setEditingProjectId(null);
                      if (e.key === "Enter") confirmEditProject();
                    }}
                    autoFocus
                  />
                ) : (
                  <Text
                    className="productTitle"
                    fontWeight="bold"
                    cursor="pointer"
                  >
                    {project.name || t("untitledProject")}
                  </Text>
                )}

                <Text className="asin" color="gray.500">
                  <Text className="multilingual" color="gray.500">
                    <Badge>{t("multilingual")}</Badge>
                  </Text>
                  <Badge>{project.asin || t("creationMode")}</Badge>
                </Text>
              </CardBody>
            </Card>,
          );
        }
      });
    }

    return [...folderList, ...projectList];
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <Box m="0px 0px" p={5}>
      <SimpleGrid
        columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
        spacing={{ base: 1, sm: 15, md: 15, lg: 5, xl: 5 }}
      >
        <Card
          className="dashcard project create"
          key="create"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          onClick={() => navigate(`/create`)}
          p={0}
        >
          <CardHeader
            style={{ position: "relative" }}
            display="flex"
            alignItems="center"
          >
            {renderImage({ emoji: "+" })}
          </CardHeader>
          <CardBody className="productLabel" p={0}>
            <Text className="productTitle" fontWeight="bold" cursor="pointer">
              {t("createProject")}
            </Text>
          </CardBody>
        </Card>
        <>{renderProjectList()}</>
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("deleteProjectTitle")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t("deleteProjectConfirmation")}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmDeleteProject}>
              {t("delete")}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Dashboard;
