import React, { useState } from "react";
import {
  Box,
  useDisclosure,
  Textarea,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

interface FloatingBoxProps {
  onRephrase: (text: string, advancedInstructions: string) => void;
  onAdvancedRephrase: (text: string, advancedInstructions: string) => void;
  style?: React.CSSProperties; // Accept style prop
  text?: string;
}

const FloatingBox: React.FC<FloatingBoxProps> = ({
  onRephrase,
  onAdvancedRephrase,
  text,
  style, // Destructure the style prop
}) => {
  const [selectedText, setSelectedText] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [advancedInstructions, setAdvancedInstructions] = useState<string>("");

  const handleSelection = () => {
    const text = window.getSelection()?.toString() || "";
    setSelectedText(text);
  };

  const handleRephrase = () => {
    onRephrase(selectedText, "");
  };

  const handleAdvancedRephrase = () => {
    onOpen();
  };

  const handleAdvancedSubmit = () => {
    onAdvancedRephrase(selectedText, advancedInstructions);
    onClose();
  };

  if (!text) return null;

  return (
    <Box
      className="floating-box"
      position="fixed"
      bg="gray.800"
      color="white"
      borderRadius="md"
      p={2}
      zIndex={1000}
      boxShadow="lg"
      onMouseUp={handleSelection}
      style={style} // Apply the passed style prop
    >
      <Text className="subtitle">
        <small>Sélection</small>
      </Text>
      <Button size="sm" onClick={handleRephrase} className="btn-rephrase">
        Reformuler
      </Button>
      <Button
        size="sm"
        onClick={handleAdvancedRephrase}
        className="btn-advanced-rephrase"
        ml={2}
      >
        Reformuler (avancé)
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reformuler avancé</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Modifier le ton ou ajouter des instructions spécifiques..."
              value={advancedInstructions}
              onChange={(e) => setAdvancedInstructions(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAdvancedSubmit}>
              Reformuler
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FloatingBox;
