import React, { useEffect } from "react";
import {
  Box,
  useDisclosure,
  Portal,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Footer from "../components/Footer/Footer";
import { useAuth } from "../context/AuthContext";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";

interface PrivateLayoutProps {
  children: React.ReactNode;
  breadcrumbItems: { label: string; href: string }[];
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  breadcrumbItems,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const mainPanel = React.createRef<HTMLDivElement>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  return (
    <Box style={{ display: "flex" }}>
      {/* Sidebar visible uniquement sur les grands écrans */}
      <Sidebar />

      {/* Drawer pour les petits écrans */}
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        finalFocusRef={mainPanel}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <Sidebar />
        </DrawerContent>
      </Drawer>

      {/* Navbar avec bouton burger pour les petits écrans */}
      <Portal>
        <AdminNavbar>
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            onClick={onOpen}
            display={{ base: "block", xl: "none" }} // Affiche le bouton burger uniquement sur mobile
          />
        </AdminNavbar>
      </Portal>

      {/* Main panel ajusté selon l'état de la Sidebar */}
      <MainPanel
        w={{
          base: "100%",

          xl: "calc(100% - 260px)", // Ajuster la largeur en fonction de la Sidebar
        }}
        marginLeft={{ xl: "260px" }}
        ref={mainPanel}
      >
        <PanelContent>
          <PanelContainer>
            <Flex>
              <Breadcrumbs items={breadcrumbItems} />
            </Flex>
            <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
              {children}
            </Flex>
          </PanelContainer>
        </PanelContent>
        <Footer />
      </MainPanel>
    </Box>
  );
};

export default PrivateLayout;
