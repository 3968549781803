import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Button, Input, useToast } from "@chakra-ui/react";

const Signup = () => {
  const { signupWithEmail } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signupWithEmail(email, password);
      const userId = userCredential.user?.uid;

      // Appel backend pour enregistrer l'utilisateur en base de données
      if (userId) {
        await fetch("/.netlify/functions/createUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            email,
          }),
        });

        toast({
          title: "Compte créé avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        navigate("/dashboard");
      }
    } catch (error: unknown) {
      let errorMessage = "Une erreur est survenue";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast({
        title: "Erreur lors de la création du compte",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" isLoading={loading}>
          S'inscrire
        </Button>
      </form>
    </Box>
  );
};

export default Signup;
