// src/App.tsx
import React, { useState } from "react";
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { Route, Routes, useParams } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";
import PrivateLayout from "./layouts/PrivateLayout";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Presentation from "./pages/Presentation";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Subscribe from "./pages/Subscribe";
import Account from "./pages/Account";
import PrivateRoute from "./components/PrivateRoute";
import ViewProject from "./components/ViewProject";
import PublicRoute from "./components/PublicRoute";
import themeAdmin from "./theme/themeAdmin";
import themePublic from "./theme/themePublic";
import CreateProject from "./components/CreateProject";
import { useTranslation } from "react-i18next";
import "./App.css";

function App() {
  const [projectName, setProjectName] = useState<string>("Projet");
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();

  const { setColorMode } = useColorMode();

  setColorMode("dark");

  return (
    <Routes>
      {/* Routes publiques avec le layout public */}
      <Route
        path="/"
        element={
          <ChakraProvider theme={themePublic}>
            <PublicLayout>
              <Login />
            </PublicLayout>
          </ChakraProvider>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <ChakraProvider theme={themePublic}>
              <PublicLayout>
                <Login />
              </PublicLayout>
            </ChakraProvider>
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <ChakraProvider theme={themePublic}>
              <PublicLayout>
                <Signup />
              </PublicLayout>
            </ChakraProvider>
          </PublicRoute>
        }
      />
      {/* Routes privées avec le layout privé */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <ChakraProvider theme={themeAdmin}>
              <PrivateLayout
                breadcrumbItems={[
                  { label: t("dashboard"), href: "/dashboard" },
                ]}
              >
                <Dashboard />
              </PrivateLayout>
            </ChakraProvider>
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/create"
        element={
          <PrivateRoute>
            <ChakraProvider theme={themeAdmin}>
              <PrivateLayout
                breadcrumbItems={[
                  { label: t("dashboard"), href: "/dashboard" },
                  { label: t("createAProject"), href: "/create" },
                ]}
              >
                <CreateProject />
              </PrivateLayout>
            </ChakraProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/account"
        element={
          <PrivateRoute>
            <ChakraProvider theme={themeAdmin}>
              <PrivateLayout
                breadcrumbItems={[
                  { label: t("dashboard"), href: "/dashboard" },
                  { label: t("account"), href: "/account" },
                ]}
              >
                <Account />
              </PrivateLayout>
            </ChakraProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/subscribe"
        element={
          <PrivateRoute>
            <ChakraProvider theme={themeAdmin}>
              <PrivateLayout
                breadcrumbItems={[
                  { label: t("dashboard"), href: "/dashboard" },
                  { label: "Subscribe", href: "/subscribe" },
                ]}
              >
                <Subscribe />
              </PrivateLayout>
            </ChakraProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:projectId"
        element={
          <PrivateRoute>
            <ChakraProvider theme={themeAdmin}>
              <PrivateLayout
                breadcrumbItems={[
                  { label: t("dashboard"), href: "/dashboard" },
                  { label: projectName, href: `/projects/${projectId}` },
                ]}
              >
                <ViewProject setProjectName={setProjectName} />
              </PrivateLayout>
            </ChakraProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/folder/:projectFolderId"
        element={
          <PrivateRoute>
            <ChakraProvider theme={themeAdmin}>
              <PrivateLayout
                breadcrumbItems={[
                  { label: t("dashboard"), href: "/dashboard" },
                  { label: t("dossier"), href: `/folder/${projectId}` },
                ]}
              >
                <Dashboard />
              </PrivateLayout>
            </ChakraProvider>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
